<template>
  <div class="container">
    <div class="head">
      <img src="../../../static/head-logo.png" class="logo">
      <span class="head_name" v-text="name" />
    </div>
    <div class="content">
      <div class="div div1">
        <router-link
          ref="divleft"
          to="/Me"
          class="left"
          @click.native="person()"
        >
          <img ref="leftimg" :src="flag ? imgL1 : imgL2" class="div1-img">
          <span>个人资料</span>
          <hr v-if="hrshowR" ref="lefthr" class="left-hr">
        </router-link>
        <hr class="hr-span">
        <router-link
          ref="divright"
          to="/Me"
          class="right"
          @click.native="reset()"
        >
          <img ref="rightimg" :src="flag ? imgR1 : imgR2" class="div1-img">
          <span>修改密码</span>
          <hr v-if="hrshowL" ref="lefthr" class="left-hr">
        </router-link>
      </div>
      <hr class="div2-hr">
      <div v-if="flag" class="person">
        <div class="div div2">
          <span
            style="color: #2c2b2b; font-size: 3.73vw; font-weight: 600"
          >基本信息</span>
          <div class="li">
            <span
              class="span-left"
            >性&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;别</span>
            <span class="span-right">{{ sex }}</span>
          </div>
          <div class="li">
            <span class="span-left">证件类型</span>
            <span class="span-right">{{ certificateType }}</span>
          </div>
          <div class="li">
            <span class="span-left">证件号码</span>
            <span class="span-right">{{ certificateNum }}</span>
          </div>
          <div class="li">
            <span
              class="span-left"
            >邮&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;箱</span>
            <span class="span-right">{{ email }}</span>
          </div>
          <div class="li">
            <span class="span-left">手机号码</span>
            <span class="span-right">{{ phone }}</span>
            <img
              src="../../../static/right.png"
              class="li-span"
              @click="updatephone()"
            >
          </div>
        </div>
        <hr class="div2-hr">
        <div class="div div3">
          <span
            style="color: #2c2b2b; font-size: 3.73vw; font-weight: 600"
          >身份信息</span>
          <div class="div3-content">
            <div v-for="(item, index) in identitys" class="table">
              <div class="title">{{ item.orgName }}</div>
              <div class="msg">
                <span>身份：{{ item.categoryName }}</span>
                <span>学工号：{{ item.identity }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-if="flag2" class="updatemima">
        <div class="up-content">
          <div class="up-li">
            <span>手&nbsp;&nbsp;机&nbsp;&nbsp;号：</span>
            <input
              v-model="phone"
              class="up-input"
              type="span"
              placeholder-style="color:#ddd;font-size:3.2vw;margin-left:4.6vw;"
              disabled="disabled"
            >
          </div>
          <div class="up-li">
            <span>验&nbsp;&nbsp;证&nbsp;&nbsp;码：</span>
            <input
              v-model="code"
              class="up-input"
              type="span"
              placeholder="短信验证码"
              placeholder-style="color:#ddd;font-size:3.2vw;margin-left:4.6vw;"
            >
            <hr class="up-hr">
            <span
              v-if="yz_btn_show"
              class="up-span"
              @click="yz()"
            >获取验证码</span>
            <span v-if="yz_text_show" class="yz_text">{{ time }}</span>
          </div>
          <div class="up-li">
            <span>新&nbsp;&nbsp;密&nbsp;&nbsp;码：</span>
            <input
              v-model="password"
              type="password"
              class="up-input"
              placeholder="请输入新密码"
              placeholder-style="color:#ddd;font-size:3.2vw;margin-left:4.6vw;"
            >
          </div>
          <span
            v-if="show2"
            id="mima_span"
            style="color: red"
            v-text="mima_span_value2"
          />
          <!-- 密码强度组件 -->
          <div v-show="strongshow" class="mimastrong">
            <div class="strongcolor">
              <span class="item1" />
              <span v-show="item2show" class="item2" />
              <span v-show="item3show" class="item3" />
            </div>
            <span class="strongmsg" v-text="mimastrong" />
          </div>
          <div class="up-li">
            <span>确认密码：</span>
            <input
              v-model="newpwd"
              type="password"
              class="up-input"
              placeholder="再次确认新密码"
              placeholder-style="color:#ddd;font-size:3.2vw;margin-left:4.6vw;"
            >
          </div>
          <span
            v-if="mimashow"
            id="mima_span"
            style="color: red"
            v-text="mima_span_value"
          />
        </div>
        <div class="footer">
          <span class="end" @click="wc()">确&nbsp;认</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/*
 *api
 */
import { user, sendSms, checkSms, updatePwd, checkWorkNo } from '../../../api';
/*
 * 正则校验
 */
import {
  isPhone,
  isNewPwdBlank,
  isNewPwdStrength,
  isNewPwdChinese,
  isNewPwdStrengthStronger
} from '../../../util/validate';
export default {
  data() {
    return {
      name: '', // 名字
      sex: '', // 性别
      certificateType: '', // 证件类型
      certificateNum: '', // 身份证号
      email: '', // 邮箱
      phone: '', // 手机号
      phoneValue: '', // 手机号密文
      identitys: [
        // 身份信息
        {
          orgName: '', // 身份信息标题
          categoryName: '', // 身份
          identity: '' // 学工号
        },
        {
          orgName: '', // 身份信息标题
          categoryName: '', // 身份
          identity: '' // 学工号
        },
        {
          orgName: '', // 身份信息标题
          categoryName: '', // 身份
          identity: '' // 学工号
        }
      ],
      orgName: '', // 身份信息标题
      categoryName: '', // 身份
      identity: '', // 学工号
      extWorkNo: '', // 学工号
      hrshowR: true,
      hrshowL: false,
      flag: true,
      flag2: false,
      imgL1: require('../../../static/me.png'),
      imgL2: require('../../../static/me-before.png'),
      imgR1: require('../../../static/me-mima.png'),
      imgR2: require('../../../static/mima.png'),
      yz_btn_show: true,
      yz_text_show: false,
      time: 60,
      code: '',
      passCode: '',
      password: '',
      newpwd: '',
      mimashow: false,
      show: false,
      mima_span_value: '',
      show2: false,
      mima_span_value2: '',
      mimastrong: '较弱',
      strongshow: false,
      item2show: 0,
      item3show: 0
    };
  },
  updated() {
    this.strongshow = !!this.password;
    if (isNewPwdStrengthStronger(this.password)) {
      this.mimastrong = '较强';
      this.item2show = 1;
      this.item3show = 1;
    } else {
      if (isNewPwdStrength(this.password)) {
        this.mimastrong = '一般';
        this.item2show = 1;
      } else {
        this.item2show = 0;
        this.item3show = 0;
      }
    }
  },
  created() {
    this.userPersonalInfo();
  },
  mounted() {
    this.userPersonalInfo();
  },
  methods: {
    person() {
      if (this.flag2) {
        this.$refs.divright.$el.style.color = '#777676';
        this.$refs.divleft.$el.style.color = '#DA251C';
        this.hrshowL = !this.hrshowL;
        this.hrshowR = !this.hrshowR;
        this.flag = !this.flag;
        this.flag2 = !this.flag2;
      }
    },
    reset() {
      if (this.flag) {
        this.$refs.divleft.$el.style.color = '#777676';
        this.$refs.divright.$el.style.color = '#DA251C';
        this.hrshowL = !this.hrshowL;
        this.hrshowR = !this.hrshowR;
        this.flag = !this.flag;
        this.flag2 = !this.flag2;
      }
    },
    confirm() {
      this.userPersonalInfo();
    },
    userPersonalInfo() {
      var that = this;
      console.log(
        'that.$data.name：' + that.$data.name,
        'that.name：' + that.name
      );
      user()
        .then((res) => {
          console.log('res' + res);
          console.log(typeof res.data.code);
          if (res.data.code == '200' || res.data.code == 200) {
            let v = res.data.data;
            console.log('v：' + v, typeof v);
            that.name = v.name;
            that.sex = v.sex == '1' ? '男' : v.sex == '2' ? '女' : '待完善';
            that.certificateType =
              v.certificateType == 'idcard'
                ? '身份证'
                : v.certificateType == 'passport'
                  ? '护照'
                  : '军官证';
            that.certificateNum = v.certificateNum;
            that.email = v.email;
            that.phone = v.phone;
            that.identitys = v.identity;
            that.extWorkNo = v.extWorkNo;
            // this.orgName = v.identitys[0].orgName;
            // this.categoryName = v.identitys[0].categoryName;
            // this.identity = v.identitys[0].identity;
          }
          console.log('that.identitys：' + that.identitys);
        })
        .catch((err) => {
          if (err.response) {
            console.log(err.response.headers);
            let loginUrl = err.response.headers.loginurl;
            console.log('loginUrl' + loginUrl);
            if (loginUrl) {
              let backUrl = window.location.hash;
              let localBaseIndexOf = loginUrl.indexOf('redirect_uri=');
              if (localBaseIndexOf != -1) {
                let localBase = loginUrl.substring(
                  localBaseIndexOf + 13,
                  loginUrl.indexOf('oauth2-login')
                );
                backUrl = window.location.href.replace(localBase, '');
              }
              // 跳转到系统登录页面
              // debugger
              console.log(
                loginUrl +
                  '?backUrl=' +
                  encodeURIComponent(encodeURIComponent(backUrl))
              );
              window.location.href =
                loginUrl +
                '?backUrl=' +
                encodeURIComponent(encodeURIComponent(backUrl));
            }
          }
        });
      // this=that
    },
    mimayz() {
      if (this.password) {
        if (
          this.password.length < 8 ||
          this.password.length > 16 ||
          !isNewPwdStrength(this.password)
        ) {
          this.mima_span_value2 = '8-16位(含字母、数字和特殊字符)';
          this.show2 = true;
        } else {
          this.show2 = false;
          if (!isNewPwdBlank(this.password)) {
            this.mima_span_value2 = '格式错误，存在空格';
            this.show2 = true;
          } else {
            this.show2 = false;
            if (!isNewPwdChinese(this.password)) {
              this.mima_span_value2 = '格式错误，存在中文';
              this.show2 = true;
            } else {
              this.show2 = false;
            }
          }
        }
      } else {
        this.mima_span_value2 = '请输入内容';
        this.show2 = true;
      }
    },
    async yz() {
      await checkWorkNo({
        workNo: this.extWorkNo
      }).then(async res => {
        const { code, data, msg } = res.data;
        if (code === 200) {
          this.phoneValue = data.text;

          await sendSms({
            mobileNumber: data.text
          })
            .then((res) => {
              if (res.data.code === 200) {
                // 获取验证码一分钟数字等待
                this.yz_btn_show = false;
                this.yz_text_show = true;
                setInterval(() => {
                  this.time--;
                  if (this.time < 0) {
                    this.yz_btn_show = true;
                    this.yz_text_show = false;
                    this.time = 60;
                    clearInterval();
                  }
                }, 1000);
                this.$message.success(res.data.msg);
              } else {
                this.$message.error(res.data.msg);
              }
            })
            .catch((err) => {
              if (err.response) {
                this.$message.error(err.response.data.errorDescription);
                console.log(err.response.headers);
                let loginUrl = err.response.headers.loginurl;
                console.log('loginUrl' + loginUrl);
                if (loginUrl) {
                  let backUrl = window.location.hash;
                  let localBaseIndexOf = loginUrl.indexOf('redirect_uri=');
                  if (localBaseIndexOf != -1) {
                    let localBase = loginUrl.substring(
                      localBaseIndexOf + 13,
                      loginUrl.indexOf('oauth2-login')
                    );
                    backUrl = window.location.href.replace(localBase, '');
                  }
                  // 跳转到系统登录页面
                  // debugger
                  console.log(
                    loginUrl +
                        '?backUrl=' +
                        encodeURIComponent(encodeURIComponent(backUrl))
                  );
                  window.location.href =
                        loginUrl +
                        '?backUrl=' +
                        encodeURIComponent(encodeURIComponent(backUrl));
                }
              }
            });
        } else {
          this.$message.error(msg);
          return false;
        }
      });
    },
    wc() {
      this.mimayz();
      if (this.phone && this.code) {
        checkSms({
          mobileNumber: this.phone,
          code: this.code
        })
          .then((res) => {
            this.passCode = res.data.data;
            if (res.data.code == 200) {
              if (!this.show) {
                updatePwd({
                  mobileNumber: this.phone,
                  newPwd: this.newpwd,
                  passCode: this.passCode
                })
                  .then((res2) => {
                    this.$router.push('/Userlogin');
                    console.log(res2);
                    if (res2.data.code == 200) {
                      this.$message.success(res2.data.msg);
                      this.activeName = 'personalInformation';
                      this.passCode = '';
                      this.newpwd = '';
                      this.mobileNumber = '';
                      this.phone = '';
                      let redirectUrl = encodeURIComponent(
                        http() + '/user/loginOut'
                      );
                      window.location.href =
                        'https://portal.jsu.edu.cn/oauth2/logout?client_id=Bz6n8VwRQRG43tVxrWVxPw&response_type=code' +
                        '&redirect_uri=' +
                        redirectUrl;
                      this.flag = true;
                    }
                    this.$router.go(0);
                  })
                  .catch((err2) => {
                    if (err2.response) {
                      this.$message.error(err2.response.data.errorDescription);
                      let loginUrl = err2.response.headers.loginurl;
                      if (loginUrl) {
                        let backUrl = window.location.hash;
                        let localBaseIndexOf =
                          loginUrl.indexOf('redirect_uri=');
                        if (localBaseIndexOf != -1) {
                          let localBase = loginUrl.substring(
                            localBaseIndexOf + 13,
                            loginUrl.indexOf('oauth2-login')
                          );
                          backUrl = window.location.href.replace(localBase, '');
                        }
                        window.location.href =
                          loginUrl +
                          '?backUrl=' +
                          encodeURIComponent(encodeURIComponent(backUrl));
                      }
                    }
                  });
              } else {
                this.mimashow = true;
              }
            } else {
              this.$message.error(res.data.msg);
            }
          })
          .catch((err) => console.log(err));
      } else {
        this.$message.warning('请先获取验证码，并输入正确的验证码！');
      }

      if (this.newpwd) {
        if (this.newpwd.length < 8 || this.newpwd.length > 16) {
          this.mima_span_value = '8-16位(含字母、数字和特殊字符)';
          this.mimashow = true;
          this.show = true;
        } else {
          if (!isNewPwdBlank(this.newpwd)) {
            this.mima_span_value = '格式错误，存在空格';
            this.mimashow = true;
            this.show = true;
          } else {
            if (!isNewPwdChinese(this.newpwd)) {
              this.mima_span_value = '格式错误，存在中文';
              this.mimashow = true;
              this.show = true;
            } else {
              if (this.password != this.newpwd) {
                this.mima_span_value = '两次密码输入不一致';
                this.mimashow = true;
                this.show = true;
              } else {
                this.show = false;
                this.mimashow = false;
              }
            }
          }
        }
      } else {
        this.mimashow = false;
      }
    },
    updatephone() {
      this.$router.push({
        name: 'Updatephone-app'
      });
    }
  }
};
</script>

<style scoped>
a {
  text-decoration: none;
}
input::placeholder {
  color: #ddd;
  font-size: 3.2vw;
  margin-left: 4.6vw;
}
input:-moz-placeholder {
  color: #ddd;
  font-size: 3.2vw;
  margin-left: 4.6vw;
}
.container {
  height: 100%;
  background-color: #f7f7f7;
  font-size: 3.73vw;
  padding-bottom: 6.14vw;
}

.head {
  width: 100vw;
  height: 62vw;
  background-image: url(../../../static/mebg.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 4.3vw;
}

.head_name {
  color: #777676;
  margin-top: 2vw;
}

.logo {
  width: 19.73vw;
  height: 19.73vw;
  margin-top: 4.44vw;
}

.content {
  width: 93.3vw;
  margin-left: 3.3vw;
  margin-top: -14.4vw;
  height: 146.13vw;
  background-color: #fff;
  border-radius: 1.3vw;
  display: flex;
  flex-direction: column;
}

.div1 {
  margin: 0 12.1vw;
  position: relative;
}

.left,
.right {
  height: 14.4vw;
  line-height: 14.5vw;
  display: flex;
  align-items: center;
}

.left {
  color: #da251c;
  float: left;
}

.right {
  color: #777676;
  float: right;
}

.hr-span {
  position: absolute;
  top: 3.46vw;
  left: 50%;
  height: 8.4vw;
  width: 0.13vw;
  background-color: #d2d2d2;
  border: none;
}

.left-hr {
  position: absolute;
  top: 14.14vw;
  height: 0.53vw;
  width: 24vw;
  background-color: #da251c;
  border: none;
}

.div1-img {
  width: 5.87vw;
  height: 5.87vw;
}

.div2 {
  margin: 5.87vw 11.2vw 8.13vw 5.2vw;
  position: relative;
  display: flex;
  flex-direction: column;
}

.div2 .li {
  margin-top: 6.53vw;
  font-size: 3.2vw;
}

.div2 .span-left {
  float: left;
  color: #464646;
}

.div2 .span-right {
  float: right;
  color: #a8a8a8;
}

.li-span {
  width: 2vw;
  height: 3.34vw;
  position: absolute;
  right: -5vw;
  bottom: 0.7vw;
}

.div2-hr {
  width: 83vw;
  margin-left: 5vw;
  background-color: #d2d2d2;
  border-top: none;
}

.div3 {
  margin: 5.47vw 5.1vw 10vw;
}

.div3-content {
  box-sizing: border-box;
  padding: 0 2vw;
  margin-left: -5vw;
  width: 94vw;
  display: flex;
  margin-top: 7.6vw;
}

.div3-content .table {
  height: 29.34vw;
  display: flex;
  flex-direction: column;
  text-align: center;
  border-radius: 2vw;
  box-shadow: 0 0 1vw #e3e3e3;
  border: 1px solid #e3e3e3;
  margin-right: 2vw;
  flex: 1;
}

.table:last-child {
  margin: 0;
}
.table .title {
  background-color: #f8f8f8;
  color: #474444;
  font-weight: 600;
  height: 9.8vw;
  line-height: 9.8vw;
}

.table .msg {
  display: flex;
  flex-direction: column;
}

.msg span {
  color: #404040;
  font-size: 3.2vw;
  margin-top: 3.87vw;
}

.up-li {
  color: #404040;
  font-size: 3.73vw;
  display: flex;
  padding-left: 6.93vw;
  line-height: 10.67vw;
  margin-top: 8vw;
  position: relative;
  border-radius: 2.67vw;
}

#mima_span {
  position: absolute;
  left: 30vw;
}

.mimastrong {
  position: relative;
  left: 27vw;
  width: 66vw;
  height: 8vw;
  line-height: 8vw;
  top: 5vw;
  display: flex;
}
.strongcolor {
  margin-top: 2vw;
  width: 66vw;
  height: 4vw;
  display: grid;
  grid-template-columns: repeat(3, 33%);
  grid-column-gap: 1vw;
}

.strongcolor .item1 {
  background-color: #fbc4c6;
}
.strongcolor .item2 {
  background-color: #f89c95;
}
.strongcolor .item3 {
  background-color: #f56c6c;
}
.strongmsg {
  margin-left: 2vw;
  width: 18vw;
  text-align: center;
  color: #da251c;
  font-size: 3.8vw;
}

.yz_text {
  position: absolute;
  right: 15vw;
  top: 1vw;
}
input {
  border: 1px solid #cfcfcf;
  height: 10.67vw;
  width: 62.53vw;
  border-radius: 2.67vw;
  outline: none;
}

.up-span,
.up-hr {
  position: absolute;
  right: 7.3vw;
}

.up-hr {
  position: absolute;
  top: 25%;
  right: 29vw;
  height: 6vw;
  width: 0.13vw;
  background-color: #a0a0a0;
  border-left: none;
}

.up-span {
  color: #da251c;
  font-size: 3.2vw;
  top: 4%;
}

.footer {
  position: relative;
  top: 13.3vw;
  width: 100%;
  padding: 0 10vw;
}

.end {
  display: block;
  width: 63.3vw;
  height: 6vh;
  background-image: url(../../../static/denglu.png);
  background-size: 100% 100%;
  font-size: 4vw;
  color: #fff;
  text-align: center;
  line-height: 6vh;
  margin-left: 7.7vw;
}
</style>
